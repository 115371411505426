import React from 'react';
import logo from '../logo.svg';

import './SiteHeader.css';

const SiteHeader = () => 

    <header className="site-title">
        <img src={logo} alt="Selazar logo" />
        <h1>Returns Portal</h1>
        <p>Fill in the details to create a return</p>
        
    </header>;

export default SiteHeader;