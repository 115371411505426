import React, {Component} from "react";
import "./OrderDetails.css";

const Reason = ( { id, showReason, showReasonComment, reason, reasonComment, handleOrderReasonChange, handleOrderReasonCommentChange } ) =>

(showReason) 
? <React.Fragment>

    <div className="form-input-order-reason">

        <select defaultValue={reason} id={id} onChange={handleOrderReasonChange}>

            <option value="">Please select a reason for the return...</option>
            <option value="NoLongerWanted">I no longer want/need the product</option>
            <option value="NotAsDescribed">The product is not as described</option>
            <option value="DifferentProductReceived">I received a different product</option>
            <option value="IncorrectSizeOrColour">I received the incorrect size or colour</option>
            <option value="NotWorking">The product does not work</option>
            <option value="Faulty">The product is faulty or defective</option>
            <option value="Damaged">The product arrived damaged</option>
            <option value="IncorrectProductOrdered">I ordered the wrong product</option>
            <option value="BetterPrice">I found a better price elsewhere</option>
            <option value="Other">Other</option>

        </select>

    </div>
    {showReasonComment ?
    <div className="form-input-order-reason-comment">

        <textarea placeholder="Please explain the reason for the return" defaultValue={reasonComment} id={id} onChange={handleOrderReasonCommentChange}></textarea>

    </div> : null}

</React.Fragment> : null;

const Item = ( { id, name, included, quantity, max, skuCode, showReason, showReasonComment, reason, reasonComment, handleOrderReasonChange, handleOrderReasonCommentChange, handleOrderChangeAddRemove, handleOrderChangeQuantity } ) =>

    <div className="form-input-order">
        <div className="float-left">
            <label>{name} {(skuCode != null) ? "(" + skuCode + ")" : ""}</label>
        </div>
        <div className="float-right">

        <label className="container">
  <input type="checkbox" checked={included} onChange={() => handleOrderChangeAddRemove(id)}></input>
  <span className="checkmark"></span>
  </label>
            <input type="number" id={id} min={1} max={max} value={quantity} onChange={handleOrderChangeQuantity} />
        </div>
        <Reason id={id} showReason={showReason} showReasonComment={showReasonComment} reason={reason} reasonComment={reasonComment} handleOrderReasonChange={handleOrderReasonChange} handleOrderReasonCommentChange={handleOrderReasonCommentChange} />
    </div>;


export class OrderDetails extends Component {
    render() {
        const { order = [], handleNext, handleBack, handleOrderReasonChange, handleOrderReasonCommentChange, handleOrderChangeAddRemove, handleOrderChangeQuantity } = this.props;

        return(
            <React.Fragment>

                    {order.length && order.map(i => 
                        <Item key={i.id} id={i.id} name={i.name} max={i.max} skuCode={i.skuCode} included={i.included} quantity={i.quantity} showReason={i.showReason} showReasonComment={i.showReasonComment} reason={i.reason} reasonComment={i.reasonComment} handleOrderReasonChange={handleOrderReasonChange} handleOrderReasonCommentChange={handleOrderReasonCommentChange} handleOrderChangeAddRemove={handleOrderChangeAddRemove} handleOrderChangeQuantity={handleOrderChangeQuantity}></Item>)}
                    <div className="buttons">

                        <button className="secondary" onClick={handleBack}>Back</button>
                        <button onClick={handleNext}>Create Return</button>

                    </div>

            </React.Fragment>
        );
    }
}

export default OrderDetails;