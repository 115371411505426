import React from 'react';

import './Stepper.css';

const Stepper = ( { activeStep = 0 } ) => 
    <ul className="stepper">
        <li className={activeStep >= 0 ? "active" : undefined}>Order Search</li>
        <li className={activeStep >= 1 ? "active" : undefined}>Order Details</li>
        <li className={activeStep >= 2 ? "active" : undefined}>Print Labels</li>
    </ul>;

export default Stepper;