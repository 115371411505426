import React from 'react';
import './ContentHeader.css';

const ContentHeader = ( { title, text } ) => 

    <div className="content-header">

        {title && <h2>{title}</h2>}
        {text && <p>{text}</p>}

    </div>;

export default ContentHeader;