import React, { Component } from "react";
import OrderSearch from "./OrderSearch";
import OrderDetails from "./OrderDetails";
import PrintReturn from "./PrintReturn";
import ContentHeader from "./common/ContentHeader";
import Endpoints from "./common/Endpoints";
import AccessDenied from "./AccessDenied";
import Modal from "./common/Modal";
import MapModal from "./common/MapModal";
import SiteHeader from "./SiteHeader";
import Stepper from "./common/Stepper";

import "./ReturnsPortal.css";

const SEARCH_HEADER_TITLE = "Create Return";
const SEARCH_HEADER_TEXT = "To create a new return enter your order number your customer email below.";
const ORDER_HEADER_TITLE = "Your Order";
const ORDER_HEADER_TEXT = "Please select which items you are returning from the items listed below.";
const PRINT_HEADER_TITLE = "Return Created";
const PRINT_HEADER_TEXT = "A Return has been generated on our system.";
const ORDER_FAIL_MODAL_TITLE = "Whoops"
const ORDER_FAIL_MODAL_TEXT = "Sorry we could not find details of the order number or customer email you have entered.";
const LOOKUP_FAIL_MODAL_TITLE = "Whoops"
const LOOKUP_FAIL_MODAL_TEXT = "Sorry we could not find details for the postcode you have entered.";
const RETURN_FAIL_MODAL_TITLE = "Whoops"
const RETURN_FAIL_MODAL_TEXT = "Sorry we could process the return at this time, please ensure that you have selected item(s) to return along with the return reason.";

function getStepContent(activeStep, retailerList, orderNumber, email, order, labelUrl, collectPlusBarcode, recaptcha, handleChange, handleNext, handleBack, handleOrderChangeAddRemove, handleOrderChangeQuantity, handleRecaptcha, handlePostcodeLookup, handleOrderReasonChange, handleOrderReasonCommentChange) {
    switch (activeStep) {
        case 0:
            return (
                <fieldset>

                    <ContentHeader title={SEARCH_HEADER_TITLE} text={SEARCH_HEADER_TEXT} />
                    <OrderSearch retailerList={retailerList} orderNumber={orderNumber} email={email} recaptcha={recaptcha} handleChange={handleChange} handleNext={handleNext} handleRecaptcha={handleRecaptcha} />

                </fieldset>
            );
        case 1:
            return (
                <fieldset>

                    <ContentHeader title={ORDER_HEADER_TITLE} text={ORDER_HEADER_TEXT} />
                    <OrderDetails order={order} handleBack={handleBack} handleNext={handleNext} handleOrderChangeAddRemove={handleOrderChangeAddRemove} handleOrderChangeQuantity={handleOrderChangeQuantity} handleOrderReasonChange={handleOrderReasonChange} handleOrderReasonCommentChange={handleOrderReasonCommentChange} />

                </fieldset>
            );
        case 2:
            return (
                <fieldset>

                    <ContentHeader title={PRINT_HEADER_TITLE} text={PRINT_HEADER_TEXT} />
                    <PrintReturn labelUrl={labelUrl} collectPlusBarcode={collectPlusBarcode} orderNumber={orderNumber} handleChange={handleChange} handleBack={handleBack} handlePostcodeLookup={handlePostcodeLookup} />

                </fieldset>
            );
        default:
            throw new Error(`Unknown step:${activeStep}`);
    }
}

class ReturnsPortal extends Component {
    state = {
        activeStep: 0,
        email: "",
        orderNumber: "",
        order: [],
        orderID: "",
        retailerCompanyID: "",
        labelUrl: "",
        collectPlusBarcode: "",
        postcode: "",
        collectPlusLookup: {},
        showMap: false,
        orderSearchFailed: false,
        orderDetailsFailed: false,
        returnFailed: false,
        lookupFailed: false,
        accessDenied: false,
        modalOpen: false,
        recaptcha: true
    };

    async fetchOrders() {
        const { email, orderNumber } = this.state;
        const url = `${Endpoints.GET_ORDERS_BY_ORDER_REFERENCE_AND_EMAIL}${encodeURIComponent(orderNumber)}/${encodeURIComponent(email)}`;
        await fetch(url)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    this.setState({
                        orderSearchFailed: true,
                        modalOpen: true,
                        accessDenied: false
                    });
                }
            })
            .then(results => {
                const order = results && results.data && results.data.itemModels && results.data.itemModels.map((i,j) => 
                ({ id: i.id+ "-" + j, companyItemID: i.companyItemID, name: i.name, description: i.description, included: false, max: i.quantity, quantity: i.quantity, reason: "", reasonComment: "", showReason: false, showReasonComment: false, skuCode: i.skuCode }));
                const orderID = results && results.data && results.data.orderID;
                const retailerCompanyID = results && results.data && results.data.retailerCompanyID;
                this.setState({
                    order: order,
                    orderID: orderID,
                    retailerCompanyID: retailerCompanyID,
                    accessDenied: false
                });
            }
            ).catch((error) => {
                this.setState({
                    accessDenied: true
                });
                console.log(error);
            });
    }

    async BookReturn(returnOrder) {
        const url = Endpoints.BOOK_RETURN;
        await fetch(url, {
            headers: { "Content-Type": "application/json" },
            method: "Post",
            body: JSON.stringify(returnOrder)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    this.setState({
                        returnFailed: true,
                        modalOpen: true,
                        accessDenied: false
                    });
                }
            })
            .then(results => {
                const labelUrl = results && results.data && results.data.labelURL
                const collectPlusBarcode = results && results.data && results.data.collectPlusBarcode
                this.setState({
                    labelUrl: labelUrl,
                    collectPlusBarcode: collectPlusBarcode,
                    accessDenied: false
                });
            }
            ).catch((error) => {
                this.setState({
                    accessDenied: true
                });
                console.log(error);
            });
    }

    async LookupPostcode() {
        const { postcode } = this.state;
        const url = `${Endpoints.COLLECT_PLUS_POSTCODE_LOOKUP}${postcode.replace(/\s/g, "")}${Endpoints.COLLECT_PLUS_POSTCODE_LOOKUP_CRITERIA}`;
        await fetch(url)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    this.setState({
                        lookupFailed: true,
                        modalOpen: true,
                        accessDenied: false
                    });
                }
            })
            .then(results => {
                if (results && results.agents && results.agents.length) {
                    const collectPlusLookup = {
                        centerLat: results.lat,
                        centerLng: results.long,
                        locations: results.agents.map(location => ({
                            id: location.site_number,
                            name: location.site_name,
                            address: location.address,
                            postcode: location.postcode,
                            city: location.city,
                            lat: location.lat,
                            lng: location.long,
                            disabledAccess: location.disabled_access_code,
                            openingTimes: [
                                `Monday ${location.monday_open ? location.monday_open : "N/A"} : ${location.monday_close ? location.monday_close : "N/A"}`,
                                `Tuesday ${location.tuesday_open ? location.tuesday_open : "N/A"} : ${location.tuesday_close ? location.tuesday_close : "N/A"}`,
                                `Wednesday ${location.wednesday_open ? location.wednesday_open : "N/A"} : ${location.wednesday_close ? location.wednesday_close : "N/A"}`,
                                `Thursday ${location.thursday_open ? location.thursday_open : "N/A"} : ${location.thursday_close ? location.thursday_close : "N/A"}`,
                                `Friday ${location.friday_open ? location.friday_open : "N/A"} : ${location.friday_close ? location.friday_close : "N/A"}`,
                                `Saturday ${location.saturday_open ? location.saturday_open : "N/A"} : ${location.saturday_close ? location.saturday_close : "N/A"}`,
                                `Sunday ${location.sunday_open ? location.sunday_open : "N/A"} : ${location.sunday_close ? location.sunday_close : "N/A"}`
                            ]
                        }))
                    }
                    this.setState({
                        collectPlusLookup: collectPlusLookup,
                        showMap: true,
                        modalOpen: true,
                        accessDenied: false
                    });
                } else {
                    this.setState({
                        lookupFailed: true,
                        modalOpen: true
                    });
                }
            }
            ).catch((error) => {
                console.log(error);
            });
    }

    handleNext = async (e) => {
        e.preventDefault();
        const { activeStep, order, orderID, orderNumber, retailerCompanyID } = this.state;
        (activeStep === 0) && await this.fetchOrders();
        if (activeStep === 1) {
            const includedItems = order.filter(item => item.included === true)
            const returnItems = [];
            includedItems.map(item => returnItems.push( { itemID: item.id.substr(0, item.id.lastIndexOf("-")), companyItemID: item.companyItemID, name: item.name, description: item.description, quantity: item.quantity, reason: item.reason, reasonComment: item.reasonComment, skuCode: item.skuCode } ));
            const returnOrder = {
                "orderID" : orderID,
                "retailerCompanyID" : retailerCompanyID,
                "orderReference": orderNumber,
                "items": returnItems
            };
            const reasonNotProvided = returnItems.some(i => i.reason === "");
            const reasonCommentNotProvided = returnItems.some(i => i.reason === "Other" && i.reasonComment === "");

            (reasonNotProvided || reasonCommentNotProvided) ?  this.setState(({ returnFailed: true, modalOpen: true })) : await this.BookReturn(returnOrder);
        }
        this.setState(state => ({
            activeStep: (state.orderSearchFailed || state.returnFailed) ? state.activeStep : state.activeStep + 1
        }));
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1
        }));
    };

    handleChange = input => e => {
        this.setState({ [input]: (e.value || e.target.value) });
    };

    handleClose = () => {
        this.setState(({
            showMap: false,
            orderSearchFailed: false,
            orderDetailsFailed: false,
            returnFailed: false,
            lookupFailed: false,
            modalOpen: false
        }));
    }

    handleOrderChangeAddRemove = (id) => {
        const { order } = this.state;
        const addRemoveItem = order.find(item => item.id === id);
        
        addRemoveItem.included = !addRemoveItem.included;
        addRemoveItem.showReason = addRemoveItem.included;
        this.setState(({
            order: order
        }));
    }

    handleOrderChangeQuantity = (e) => {
        const { order } = this.state;
        const { value, id, min, max } = e.target;

        const quantityChangeItem = order.find(item => item.id === id);
        quantityChangeItem.quantity = Math.max(Number(min), Math.min(Number(max), Number(value)));

        this.setState(({
            order: order
        }));
    }

    handleOrderReasonChange = (e) => {
        const { order } = this.state;
        const { value, id } = e.target;

        const reasonChangeItem = order.find(item => item.id === id);
        reasonChangeItem.reason = value;
        value === "Other" ? reasonChangeItem.showReasonComment = true : reasonChangeItem.showReasonComment = false;

        this.setState(({
            order: order
        }));
    }

    handleOrderReasonCommentChange = (e) => {
        const { order } = this.state;
        const { value, id } = e.target;

        const reasonCommentChangeItem = order.find(item => item.id === id);
        reasonCommentChangeItem.reasonComment = value;

        this.setState(({
            order: order
        }));
    }

    handlePostcodeLookup = async () => {
        await this.LookupPostcode();
    }

    handleRecaptcha = () => {
        this.setState({ recaptcha: false });
    }

    render() {
        const { activeStep, accessDenied, orderSearchFailed, retailerList, orderNumber, email, order, labelUrl, collectPlusBarcode, modalOpen, recaptcha, showMap, collectPlusLookup, lookupFailed, returnFailed } = this.state;

        return (
            <React.Fragment>
                {accessDenied
                    ? <AccessDenied />
                    : <section className="returns">
                        <div className="returns-form">
                            <SiteHeader />
                            <Stepper activeStep={activeStep} />
                            {getStepContent(activeStep, retailerList, orderNumber, email, order, labelUrl, collectPlusBarcode, recaptcha, this.handleChange, this.handleNext, this.handleBack, this.handleOrderChangeAddRemove, this.handleOrderChangeQuantity, this.handleRecaptcha, this.handlePostcodeLookup, this.handleOrderReasonChange, this.handleOrderReasonCommentChange)}
                            {orderSearchFailed && <Modal title={ORDER_FAIL_MODAL_TITLE} text={ORDER_FAIL_MODAL_TEXT} handleClose={this.handleClose} modalOpen={modalOpen} />}
                            {lookupFailed && <Modal title={LOOKUP_FAIL_MODAL_TITLE} text={LOOKUP_FAIL_MODAL_TEXT} handleClose={this.handleClose} modalOpen={modalOpen} />}
                            {returnFailed && <Modal title={RETURN_FAIL_MODAL_TITLE} text={RETURN_FAIL_MODAL_TEXT} handleClose={this.handleClose} modalOpen={modalOpen} />}
                            {showMap && <MapModal collectPlusLookup={collectPlusLookup} mapUrl={Endpoints.MAPS_URL} handleClose={this.handleClose} modalOpen={modalOpen} />}
                        </div>
                    </section>}
            </React.Fragment>
        );
    }
}

export default ReturnsPortal;