import React from "react";
import "./Modal.css"

const Modal = ( { title, text, handleClose, modalOpen } ) => 

    (modalOpen) 
    ? <div className="modal">
        <section className="modal-content">
            <div className="modal-header">
                {title && <h1>{title}</h1>}
            </div>
            {text && <p>{text}</p>}
            <div className="modal-footer">
                <button onClick={handleClose}>Close</button>
            </div>
        </section>
    </div>
    : null;

export default Modal;