const USER_API= process.env.REACT_APP_USER_API
const RETAILER_API= process.env.REACT_APP_RETAILER_API

const Endpoints = {
    GET_ALL_RETAILERS                           : USER_API + 'api/Company/GetByProfile/Retailer',
    GET_ORDERS_BY_ORDER_REFERENCE_AND_EMAIL  : RETAILER_API + 'api/Order/GetByRetailerReferenceAndEmail/',
    BOOK_RETURN                                 : RETAILER_API + 'api/Return/Book',
    COLLECT_PLUS_POSTCODE_LOOKUP                : 'https://www.collectplus.co.uk/api/v1/agentlocator/default/AgentLocator.json?searchCriteria=',
    COLLECT_PLUS_POSTCODE_LOOKUP_CRITERIA       : '&searchType=2&maxRecords=10',
    MAPS_URL                                    : 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDXmh1OKZscTPQfdFhWF9SiiTItR84rn8k'
}

export default Endpoints;