import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./OrderSearch.css";

const captchaKey = process.env.REACT_APP_RETURNS_CAPTCHA_TOKEN;

class OrderSearch extends Component {

    render() {
        const { orderNumber, email, recaptcha, handleChange, handleNext, handleRecaptcha } = this.props;
        return(
            <React.Fragment>

                {!recaptcha && <div className="order-search">

                    <div className="order-search-input">
                        <label>Order Number</label>
                        <input id="orderNumber" placeholder="Enter order number" defaultValue={orderNumber ? orderNumber : undefined} onChange={handleChange("orderNumber")} />

                    </div>
                    <div className="order-search-input">
                        <label>Email Address</label>
                        <input id="email" placeholder="Enter your email address" defaultValue={email ? email : undefined} onChange={handleChange("email")} />

                    </div>

                        <button onClick={handleNext}>Search</button>

                </div>}

                {recaptcha && <ReCAPTCHA
                    className="search-recaptcha"
                    sitekey={captchaKey}
                    onChange={handleRecaptcha}
                />}

            </React.Fragment>
        );
    }   
}

export default OrderSearch;