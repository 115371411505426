import React, { Component } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import MarkerWhiteIcon from './marker-white.png';
import "./Modal.css"

class CollectPlusMarker extends Component {
    constructor(props) {
        super(props);
        this.onToggleInfo = this.onToggleInfo.bind(this);
        this.state = { show: false };
    }

    onToggleInfo() {
        this.setState((prevState) => ({ show: !prevState.show }));
    }

    render() {
        const { location } = this.props;

        return (
            <Marker
                position={{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) }}
                icon={MarkerWhiteIcon}
                onClick={this.onToggleInfo}>
                {
                    this.state.show &&
                    <InfoWindow>
                        <div>
                            <b>Name</b>
                            <br></br>
                            <span>{location.name}</span>
                            <br></br>
                            <br></br>
                            <b>Address</b>
                            <br></br>
                            <span>{location.address}</span>
                            <br></br>
                            <span>{location.city}</span>
                            <br></br>
                            <span>{location.postcode}</span>
                            <br></br>
                            <br></br>
                            <React.Fragment>
                                <b>Opening Hours</b>
                                <br></br>
                                {location.openingTimes.map((o, i) => <React.Fragment><span key={`${i}-${location.id}`}>{o}</span><br></br></React.Fragment>)}
                            </React.Fragment>
                            <br></br>
                            <b>Disabled Access</b>
                            <br></br>
                            <span>{location.disabledAccess}</span>
                        </div>
                    </InfoWindow>
                }
            </Marker>
        );
    }
}

const CollectPlusMap = withScriptjs(withGoogleMap(({ collectPlusLookup }) => {

    const markers = collectPlusLookup.locations.map(loc => <CollectPlusMarker
        key={loc.id}
        location={loc}

    />);

    return (
        <GoogleMap
            defaultZoom={14}
            zoomControl={true}
            center={{ lat: parseFloat(collectPlusLookup.centerLat), lng: parseFloat(collectPlusLookup.centerLng) }}>
            <Marker key="my-position" position={{ lat: collectPlusLookup.centerLat, lng: collectPlusLookup.centerLng }} />
            {markers}
        </GoogleMap>
    );
}));

const CollectPlusMapContainer = ({ collectPlusLookup, mapUrl }) =>

    <CollectPlusMap
        collectPlusLookup={collectPlusLookup}
        googleMapURL={mapUrl}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `88%`, width: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
    />;

const MapModal = ({ collectPlusLookup, mapUrl, handleClose, modalOpen }) =>

    (modalOpen)
        ?
        <div className="modal">

            <section className="modal-content-map">

                <CollectPlusMapContainer collectPlusLookup={collectPlusLookup} mapUrl={mapUrl} />
                <div className="modal-footer">
                    <button onClick={handleClose}>Close</button>
                </div>

            </section>

        </div>
        : null;

export default MapModal;