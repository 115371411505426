import React from "react";

import "./AccessDenied.css"
import notFound from '../imgs/nbg-search-results.svg';

const AccessDenied = () => 
    <div className="access-denied">
        <img src={notFound} alt="Selazar logo" />
        <h1>Whoops!</h1>
        <h2>Access Denied</h2>
        <h2>403 Error</h2>
        <p>It looks like too many submissions have been made and our system suspects this has been done by a robot</p>
    </div>;

export default AccessDenied;