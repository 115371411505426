import React, {Component} from "react";

import "./PrintReturn.css";

const STEPS_PRINT = "Please print your Collect+ label and attach it to your parcel, ensuring it covers any other labels. Please make sure that no sellotape covers the barcode.";
const STEPS_LOCATOR = "Use the store locator below to locate your nearest Collect+ Point. With over 7,000 Collect+ Points there should be one close by.";
const STEPS_SEND = "Take your parcel to the Collect+ Point, where you will be given a receipt. Your parcel will be returned within 3-5 working days.";
const COLLECT_HEADER_TITLE = "Collect+ Point Search";
const COLLECT_HEADER_TEXT = "To search for the closest Collect+ Point please use the search below.";
const TRACKING_URL = "https://www.collectplus.co.uk/track/"

class PrintReturn extends Component {

    render() {
        const { orderNumber, labelUrl, collectPlusBarcode, handleChange, handleBack, handlePostcodeLookup } = this.props;

        return (
            <React.Fragment>
                <div className="print-return-steps">
                    <h3>Next Steps</h3>
                    <ol>
                        <li>
                            {STEPS_PRINT}
                        </li>
                        <li>
                            {STEPS_LOCATOR}
                        </li>
                        <li>
                            {STEPS_SEND}
                        </li>
                    </ol>
                </div>
                <div className="print-return-label">
                    <h3>Print Return Labels For: <span>{orderNumber}</span></h3>
                    <a href={labelUrl} rel="noopener noreferrer" target="_blank"><button>Download Labels</button></a>
                </div>
                <div className="print-return-tracking">
                    <h3>Tracking</h3>
                    <p>Your tracking number is: <a href={`${TRACKING_URL}${collectPlusBarcode}`} rel="noopener noreferrer" target="_blank">{collectPlusBarcode}</a></p>
                </div>
                <div className="print-return-location-title">
                    <h3>{COLLECT_HEADER_TITLE}</h3>
                    <p>{COLLECT_HEADER_TEXT}</p>
                </div>
                <div className="print-return-location">
                    <input id="postcode" placeholder="Enter Postcode" onChange={handleChange("postcode")} />
                    <button onClick={handlePostcodeLookup}>Search</button>
                </div> 
                <div className="buttons">
                    <button className="secondary last" onClick={handleBack}>Back</button>
                </div>     
            </React.Fragment>
        );
    }

}

export default PrintReturn;